import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Image, Flex, Box, Container } from "theme-ui";

export interface HomeProps {
  data: any;
}

const docs = [
  {
    name: "Dr Abdurahman Ambadi",
    bio: "Chairman and Consultant in General and Minimally Invasive Surgery",
  },
  {
    name: "Dr Hafiz Muhammad",
    bio: "Vice-Chairman and Consultant in Plastic, Reconstructive Cosmetic Surgery",
  },
  {
    name: "Dr C C Suresh",
    bio: "Patron and Chief Consultant in General and Minimally Invasive Surgery",
  },
  {
    name: "Dr Aneez K Arakkal",
    bio: "Medical Director",
  },
];

const Home: React.FC<HomeProps> = () => (
  <Layout>
    {/* <Hero /> */}
    <Plate variant="styles.contentFrame">
      <Container sx={{ pt: 7, pb: 5 }}>
        <Plate variant="styles.content">
        <Text as="h1" variant="pageTitle">
            Our Facilities
          </Text>

        </Plate>

        <Plate variant="styles.bodyFrame">
          <Text as="p" variant="sectionBody">
            Facilities
            <ul>
              <Text as="li" variant="sectionBody">Two fully equipped theatre suite that consists of an operating theatre, an
                anaesthesia bay, a scrub area, a utility store and an OT store</Text>
              <Text as="li" variant="sectionBody">A discussion room within the OT complex for inter-departmental meetings, training
                and research purposes.</Text>
              <Text as="li" variant="sectionBody">Hygienic changing rooms, toilets and showers for male and female personnel.</Text>
              <Text as="li" variant="sectionBody">Sterilization area (CSSD) for cleaning and preparation of surgical instruments to
                ensure the safety of the patient.</Text>
              <Text as="li" variant="sectionBody">A dedicated doctors’ lounge for surgeons to relax and connect with co-workers
                during breaks</Text>
              <Text as="li" variant="sectionBody">Reception area for patients to check-in and rest while waiting for their appointment.</Text>
              <Text as="li" variant="sectionBody">A canteen, a pharmacy and a prayer hall adjacent to the surgery room for the
                convenient use of the patients and bystanders.</Text>
            </ul>
          </Text>
        </Plate>
        <Plate variant="styles.bodyFrame">
          <Text as="h3" variant="sectionTitle" sx={{ mb: 4, fontSize: 5, fontWeight: 100, color: 'primary' }}>
            For Patients
          </Text>
          <Text as="p" variant="sectionBody">
            Our idea of surgical care is founded on the convenience of the patients. Your comfort is our
            responsibility and we ensure it at each stage of surgical care. At CCS, surgeries are never a
            matter of unnecessary scare. We have surgeons, staffs and technicians to make it as easy as
            possible. Our team is committed to attend to all your queries and needs throughout your
            stay with us. We work as a family to make you feel at home.</Text>
          <Text as="h3" variant="sectionTitle" sx={{ mb: 4, fontSize: 5, fontWeight: 100, color: 'primary' }}>
            For Physicians
          </Text>

          <Text as="p" variant="sectionBody">The style of working we follow at CCS cuts down the burden physicians usually has to
            shoulder in surgical care. We have an expert team of staff and technicians to support the
            physicians and allow them to fully focus on the surgery. Surgeon-friendly policies of CCS
            help our highly qualified and experienced surgeons to exhaust their efforts in maximizing
            the efficiency of the surgical care and safety of the patients alone.</Text>

        </Plate>
      </Container>
    </Plate>
  </Layout>
);

export default Home;
